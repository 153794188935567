<script setup lang="ts">
import type { Dayjs } from "dayjs";

import type { Promotion, Badge, Game } from "@/types";

const { t } = useT();

const props = withDefaults(
	defineProps<{
		hideLabel?: boolean;
		sweepStakes: boolean;
		showTooltip?: boolean;
		showAvailable?: boolean;
		enabled?: boolean;
		image: string;
		subTitle: string;
		title: string;
		coins: number;
		entries: number;
		freeSpinsAmount?: number;
		wheelMaxWin?: Record<"coins" | "entries", number> | null;
		date: Dayjs;
		type?: Promotion["type"];
		bestDeal: boolean;
		mostPopular: boolean;
		rankLeague: boolean;
		freeSpin?: boolean;
		gameId?: number;
		showBadge: boolean;
		badge: Badge;
		currencySymbol?: string;
		money?: string;
		textTooltip?: string;
		needToWaitDailyWheel?: boolean;
		activeRushHoursOffers?: string;
		exclusionType?: boolean;
		baseline: number;
		subType?: string;
		isDepositStreakTicket?: boolean;
	}>(),
	{ hideLabel: false, showTooltip: false }
);

const emit = defineEmits<{ (event: "click"): void }>();
const { ready: readyAnimation, start: handleStartAnimation } = useTimeout(2000, { controls: true, immediate: false });
const { ready: readyPopper, start: handleStartPopper } = useTimeout(2000, { controls: true, immediate: false });

let game: ComputedRef<Game | null>;
if (props.gameId) {
	useGetGameFiltersData();
	const { select } = useGamesState();
	game = computed(() => (props.gameId ? select([props.gameId])[0] : null));
}

const handleClick = () => {
	if (props.enabled === false && props.type !== "dailyWheel") {
		handleStartAnimation();
		handleStartPopper();
		return;
	}

	emit("click");
};

const { durationLeft: promotionTime, reset } = useCountdown({ timestamp: props.date });
watch(
	() => props.date,
	(value) => {
		if (value) {
			reset(value);
		}
	}
);
</script>

<template>
	<ACard
		:imgAttrs="{
			width: 328,
			height: 257,
			alt: `card-${type}`,
			loading: 'lazy',
			format: 'avif',
			src: image
		}"
		data-tid="card-img"
		height="257px"
		:class="[`card-${type}`, sweepStakes ? 'card-entries' : '']"
		@click="handleClick"
	>
		<template #default>
			<ABadge v-if="bestDeal && !hideLabel" background="var(--cebu)" variant="skew" class="top-left" autosize>
				<AText variant="topeka" :modifiers="['bold', 'uppercase']">{{ t("Best deal") }}</AText>
			</ABadge>
			<ABadge v-if="mostPopular && !hideLabel" background="var(--cabo)" variant="skew" class="top-left" autosize>
				<AText variant="topeka" :modifiers="['bold', 'uppercase']">{{ t("Most popular") }}</AText>
			</ABadge>
			<ABadge v-if="rankLeague && !hideLabel" background="var(--cairns)" variant="skew" class="top-left" autosize>
				<AText variant="topeka" :modifiers="['bold', 'uppercase']">{{ t("Status reward") }}</AText>
			</ABadge>
			<ABadge
				v-if="freeSpin && !hideLabel"
				background="var(--chusovoy)"
				color="var(--chomutov)"
				variant="skew"
				class="top-left"
				autosize
			>
				<AText variant="topeka" :modifiers="['bold', 'uppercase']">{{ t("offer with FS") }}</AText>
			</ABadge>
			<ABadge
				v-if="type === 'verification' && !hideLabel"
				background="var(--comilla)"
				variant="skew"
				class="top-left"
				autosize
			>
				<AText variant="topeka" :modifiers="['bold', 'uppercase']">{{ t("Secure") }}</AText>
			</ABadge>
			<ABadge v-if="subType && !hideLabel" background="var(--casab)" variant="skew" class="top-left" autosize>
				<AText variant="topeka" :modifiers="['bold', 'uppercase']">{{ subType }}</AText>
			</ABadge>
			<ACornerBadge v-if="showBadge" variant="accent" modifiers="right" class="top-right">
				<AText v-if="badge.percent" variant="topeka" :modifiers="['bold']">{{ badge.percent }}</AText>
				<AText v-if="badge.text" variant="tallinn" :modifiers="['uppercase', 'normal']">{{ badge.text }}</AText>
			</ACornerBadge>
		</template>

		<template #top>
			<MGamePromotionPreview v-if="game" :game="game" />
			<div class="block">
				<template v-if="!freeSpin">
					<AText variant="ternopil" :modifiers="['normal', 'uppercase']" class="title">
						<template v-if="!showAvailable">{{ title }}</template>
						<template v-else-if="enabled">{{ t("Available for") }} {{ promotionTime }} </template>
					</AText>
					<AText variant="topeka" :modifiers="['bold', 'uppercase']" class="sub-title">
						<template v-if="!activeRushHoursOffers && type !== 'partnerReferralQualification'">{{ subTitle }}</template>
						<i18n-t v-else-if="type === 'partnerReferralQualification'" keypath="Make a {price} purchase and get EXTRA">
							<template #price> {{ currencySymbol }}{{ baseline }} </template>
						</i18n-t>
						<template v-else> 🔥 {{ activeRushHoursOffers }} {{ t("hours only!") }} </template>
					</AText>
				</template>
				<div v-if="coins !== 0" class="card-count coins">
					<NuxtImg src="/nuxt-img/cards/coins.png" alt="coins" format="webp" width="28" height="28" loading="lazy" />
					<AText variant="tanzay" class="text-chittagong" :modifiers="['black']">
						{{ numberFormat(type === "dailyWheel" && wheelMaxWin ? wheelMaxWin.coins : coins) }}
					</AText>
				</div>
				<template v-if="freeSpin">
					<div class="card-count free-spins">
						<AText v-if="freeSpinsAmount" variant="tanzay" class="text-chusovoy" :modifiers="['black']">
							<i18n-t keypath="{plus} {amount} FS">
								<template #plus>
									<span class="plus-icon">+</span>
								</template>
								<template #amount>
									{{ numberFormat(freeSpinsAmount) }}
								</template>
							</i18n-t>
						</AText>
					</div>
					<div v-if="entries" class="card-count free-spin-entries">
						<AText class="text-cixi" variant="topeka" :modifiers="['extrabold', 'uppercase']">
							<i18n-t keypath="+ {amount} entries for free">
								<template #amount>{{ numberFormat(entries) }}</template>
							</i18n-t>
						</AText>
					</div>
				</template>
				<div v-else-if="entries !== 0" class="card-count entries">
					<NuxtImg
						src="/nuxt-img/cards/entries.png"
						alt="entries"
						format="webp"
						width="28"
						height="28"
						loading="lazy"
					/>
					<AText variant="tanzay" class="text-cixi" :modifiers="['black']">
						{{ numberFormat(type === "dailyWheel" && wheelMaxWin ? wheelMaxWin.entries : entries) }}
					</AText>
					<AText
						v-if="!exclusionType"
						variant="topeka"
						class="text-cixi count-after"
						:modifiers="['bold', 'uppercase']"
						>{{ t("for free") }}</AText
					>
				</div>
			</div>
		</template>

		<template v-if="type === 'inviteQualification' || type === 'partnerReferralQualification'" #bottom>
			<AButton variant="primary" class="promo" data-tid="promo-invite-qa-buy">
				<AText variant="toledo" :modifiers="['semibold', 'uppercase']">{{ t("Qualify") }}</AText>
			</AButton>
		</template>
		<template v-else-if="type === 'verification'" #bottom>
			<AButton variant="primary" class="promo" data-tid="promo-verification">
				<AText variant="toledo" :modifiers="['semibold', 'uppercase']">{{ t("Secure now") }}</AText>
			</AButton>
		</template>
		<template v-else-if="type === 'invite'" #bottom>
			<AButton variant="primary" class="promo" data-tid="promo-share-link">
				<AText variant="toledo" :modifiers="['semibold', 'uppercase']">{{ t("Copy link") }}</AText>
			</AButton>
		</template>
		<template v-else-if="type === 'partnerReferralQualificationByVerification'" #bottom>
			<AButton variant="primary" class="promo" data-tid="promo-get-now">
				<AText variant="toledo" :modifiers="['semibold', 'uppercase']">{{ t("Get now") }}</AText>
			</AButton>
		</template>

		<template v-else-if="type === 'dailyWheel'" #bottom>
			<AButton :variant="needToWaitDailyWheel ? 'disabled' : 'primary'" class="promo" data-tid="promo-daily-wheel">
				<template v-if="needToWaitDailyWheel">
					<AText variant="tulsa"> <NuxtIcon name="20/clock" filled /></AText>
					<AText :modifiers="['semibold', 'uppercase']">{{ t("Available tomorrow") }}</AText>
				</template>
				<AText v-else variant="toledo" :modifiers="['semibold', 'uppercase']">{{ t("Spin & win") }}</AText>
			</AButton>
		</template>

		<template v-else #bottom>
			<AButton v-if="enabled" variant="primary" data-tid="promo-buy-now">
				<AText variant="toledo" :modifiers="['semibold', 'uppercase']"
					>{{ bestDeal || mostPopular ? t("only") : t("buy now") }} {{ currencySymbol }}{{ money }}</AText
				>
				<NuxtImg
					v-if="isDepositStreakTicket"
					src="/nuxt-img/deposit-streak/ticket.png"
					class="ticket-icon"
					format="webp"
					alt="ticket"
					width="48"
					height="48"
				/>
			</AButton>

			<APopper v-else class="btn-not-allowed" :content="textTooltip" placement="top" :show="!readyPopper">
				<AButton variant="disabled" data-tid="promo-not-allowed">
					<AAnimationTada :active="!readyAnimation">
						<AText variant="tulsa">
							<NuxtIcon :name="`20/lock`" filled />
						</AText>
					</AAnimationTada>
					<AText variant="toledo" :modifiers="['bold', 'uppercase']">{{ currencySymbol }}{{ money }}</AText>
				</AButton>
			</APopper>
		</template>
	</ACard>
</template>

<style scoped lang="scss">
.pre-wrap {
	white-space: pre-wrap;
}
.bottom-info {
	display: flex;
	width: 100%;
	max-width: 240px;
	justify-content: center;
	position: absolute;
	bottom: 17px;
	left: 20px;
}

.top-left,
.top-right {
	position: absolute;
	top: 0;
	left: 0;
}

.top-right {
	left: auto;
	right: 0;
}

.card-count {
	display: flex;
	align-items: center;
	gap: gutter(0.5);
}

button {
	width: 240px;
	height: 40px;
	gap: gutter(0.75);
}

.top .nuxt-icon {
	margin-right: gutter(0.5);
}

.card {
	&::before {
		content: "";
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		position: absolute;
		z-index: 0;
		background-color: var(--curug);
	}

	&:deep(.top),
	&:deep(.bottom) {
		align-items: flex-start;
	}

	.title {
		position: absolute;
		top: 42px;
		left: 20px;
		max-width: 100%;
	}

	.sub-title {
		display: block;
		min-height: 15px;
		margin-bottom: gutter(0.5);
	}

	.count-after,
	.count-before {
		display: inline-flex;
		max-width: 40px;
	}

	.sweep-stakes {
		margin-bottom: gutter(3);
	}
}

.card-dailyWheel .disabled {
	cursor: pointer;
}

.ticket-icon {
	width: 24px;
	height: 24px;
}

.free-spin-entries {
	margin-top: 6px;
}

.plus-icon {
	min-width: 28px;
	display: inline-block;
	vertical-align: top;
	text-align: center;
}
</style>
